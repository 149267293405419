var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { spacings } from "./spacings";
import { onBreakpoint, rem, BREAKPOINTS } from "./layout";
import { fonts } from "./fonts";
import { brandColors } from "./brand-colors";
export var typography = {
    h1: __assign(__assign(__assign({}, fonts.bold), { fontSize: "1.5rem", lineHeight: 1.33, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.s), display: "block" }), onBreakpoint("sm", {
        fontSize: "3rem",
        lineHeight: 1.33,
        marginBottom: rem(spacings.l),
    })),
    h2: __assign(__assign(__assign({}, fonts.bold), { fontSize: "1.25rem", lineHeight: 1.2, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.xs), display: "block" }), onBreakpoint("sm", {
        fontSize: "2.5rem",
        lineHeight: 1.2,
        marginBottom: rem(spacings.m),
    })),
    h3: __assign(__assign(__assign({}, fonts.bold), { fontSize: "1.25rem", lineHeight: 1.2, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.xs), display: "block" }), onBreakpoint("sm", {
        fontSize: "2rem",
        lineHeight: 1.25,
        marginBottom: rem(spacings.sam),
    })),
    h4: __assign(__assign(__assign({}, fonts.bold), { fontSize: "1.25rem", lineHeight: 1.2, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.xs), display: "block" }), onBreakpoint("sm", {
        fontSize: "1.5rem",
        lineHeight: 1.33,
        marginBottom: rem(spacings.s),
    })),
    h5: __assign(__assign(__assign({}, fonts.bold), { fontSize: "1.25rem", lineHeight: 1.33, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.xs), display: "block" }), onBreakpoint("sm", {
        fontSize: "1.5rem",
        lineHeight: 1.33,
        marginBottom: rem(spacings.s),
    })),
    h6: __assign(__assign(__assign({}, fonts.bold), { fontSize: "1.25rem", lineHeight: 1.33, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.xs), display: "block" }), onBreakpoint("sm", {
        fontSize: "1.5rem",
        lineHeight: 1.33,
        marginBottom: rem(spacings.s),
    })),
    textXSmall: __assign(__assign(__assign(__assign({}, fonts.medium), { fontSize: "0.625rem", lineHeight: 1.6, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.xxs), display: "block" }), onBreakpoint("sm", {
        fontSize: "0.875rem",
        lineHeight: 1.428,
        marginBottom: rem(spacings.xs),
    })), { "& a": {
            color: brandColors.universalGreen,
            transition: "color 0.2s ease",
            "&:hover": {
                color: brandColors.universalGreen,
                textDecoration: "underline",
            },
        } }),
    textSmall: __assign(__assign(__assign(__assign({}, fonts.medium), { fontSize: "0.875rem", lineHeight: 1.428, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.xs), display: "block" }), onBreakpoint("sm", {
        fontSize: "1rem",
        lineHeight: 1.5,
        marginBottom: rem(spacings.s),
    })), { "& a": {
            color: brandColors.universalGreen,
            transition: "color 0.2s ease",
            "&:hover": {
                color: brandColors.universalGreen,
                textDecoration: "underline",
            },
        } }),
    textDefault: __assign(__assign(__assign(__assign({}, fonts.medium), { fontSize: "1rem", lineHeight: 1.5, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.s), display: "block" }), onBreakpoint("sm", {
        fontSize: "1.1875rem",
        lineHeight: 1.473,
        marginBottom: rem(spacings.sam),
    })), { "& a": {
            color: brandColors.universalGreen,
            transition: "color 0.2s ease",
            "&:hover": {
                color: brandColors.universalGreen,
                textDecoration: "underline",
            },
        } }),
    textLarge: __assign(__assign(__assign(__assign({}, fonts.medium), { fontSize: "1rem", lineHeight: 1.5, color: brandColors.darkGrey, marginTop: 0, marginBottom: rem(spacings.s), display: "block" }), onBreakpoint("sm", {
        fontSize: "1.5rem",
        lineHeight: 1.33,
        marginBottom: rem(spacings.sam),
    })), { "& a": {
            color: brandColors.universalGreen,
            transition: "color 0.2s ease",
            "&:hover": {
                color: brandColors.universalGreen,
                textDecoration: "underline",
            },
        } }),
    visuallyHidden: {
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        width: "1px",
        height: "1px",
        margin: "-1px",
        padding: 0,
        border: 0,
        overflow: "hidden",
        position: "absolute",
        whiteSpace: "nowrap",
    },
    caption: __assign(__assign(__assign({}, fonts.medium), { fontSize: "1rem", lineHeight: 1.5, color: brandColors.grey, letterSpacing: 1.8, textTransform: "uppercase", marginTop: 0, display: "block" }), onBreakpoint("sm", {
        fontSize: "1.1875rem",
        lineHeight: 1.473,
    })),
    uList: __assign(__assign(__assign({ paddingLeft: 0, marginBottom: rem(spacings.s) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.sam),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.m),
    })), { "& li": __assign(__assign(__assign(__assign({}, fonts.medium), { fontSize: "1rem", lineHeight: 1.5, color: brandColors.darkGrey, listStyle: "none", marginBottom: rem(spacings.s), paddingLeft: rem(spacings.xs), display: "block" }), onBreakpoint("sm", {
            fontSize: "1.1875rem",
            lineHeight: 1.473,
        })), { "& ul": {
                marginLeft: rem(spacings.m),
                marginTop: rem(spacings.s),
                marginBottom: rem(spacings.s),
            }, "&:before": {
                position: "relative",
                fontFamily: "Font Awesome\\ 5 Pro",
                content: '"\\f178"',
                fontWeight: 300,
                color: brandColors.mediumGrey,
                marginRight: rem(spacings.s),
                fontSize: "inherit",
                top: 0,
                float: "left",
                height: 28,
                whiteSpace: "nowrap",
                "@supports (display: contents)": {
                    fontSize: "1.6rem",
                    top: 4,
                    float: "none",
                },
            }, "& a": __assign(__assign(__assign(__assign({}, fonts.medium), { fontSize: "1rem", lineHeight: 1.5, color: brandColors.universalGreen }), onBreakpoint("sm", {
                fontSize: "1.1875rem",
                lineHeight: 1.473,
            })), { "&:hover": {
                    color: brandColors.universalGreen,
                    textDecoration: "underline",
                } }), "& p": {
                marginBottom: 0,
            } }) }),
    oList: __assign(__assign(__assign({ counterReset: "counter", paddingLeft: 0, marginBottom: rem(spacings.s) }, onBreakpoint("sm", {
        marginBottom: rem(spacings.sam),
    })), onBreakpoint("md", {
        marginBottom: rem(spacings.m),
    })), { "& li": __assign(__assign(__assign(__assign({ counterIncrement: "counter" }, fonts.medium), { fontSize: "1rem", lineHeight: 1.5, color: brandColors.darkGrey, listStyle: "none", marginBottom: rem(spacings.s), paddingLeft: rem(spacings.xs), display: "block", "& ol": {
                marginLeft: rem(spacings.m),
                marginTop: rem(spacings.s),
                marginBottom: rem(spacings.s),
            } }), onBreakpoint("sm", {
            fontSize: "1.1875rem",
            lineHeight: 1.473,
        })), { "&:before": {
                position: "relative",
                content: "counter(counter) '. '",
                marginRight: rem(spacings.s),
                color: brandColors.mediumGrey,
                height: 28,
                whiteSpace: "nowrap",
            }, "& a": __assign(__assign(__assign(__assign({}, fonts.medium), { fontSize: "1rem", lineHeight: 1.5, color: brandColors.universalGreen }), onBreakpoint("sm", {
                fontSize: "1.1875rem",
                lineHeight: 1.473,
            })), { "&:hover": {
                    color: brandColors.universalGreen,
                    textDecoration: "underline",
                } }), "& p": {
                marginBottom: 0,
            } }) }),
    grayBackgroundFix: {
        "& li:before": {
            color: "".concat(brandColors.grey, " !important"),
        },
    },
    darkBackgroundFix: {
        color: "".concat(brandColors.white, " !important"),
        "& li:before": {
            color: "".concat(brandColors.white, " !important"),
        },
    },
};
export function customTypography(style, xs, sm, md) {
    var _a;
    return __assign(__assign(__assign({}, style), xs), (_a = {}, _a["@media (min-width: ".concat(BREAKPOINTS["sm"], "px)")] = __assign(__assign({}, style["@media (min-width: ".concat(BREAKPOINTS["sm"], "px)")]), sm), _a["@media (min-width: ".concat(BREAKPOINTS["md"], "px)")] = __assign(__assign({}, style["@media (min-width: ".concat(BREAKPOINTS["md"], "px)")]), md), _a));
}
